// echo.js
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';

window.io = require('socket.io-client');

const pusher = new Pusher('18721b6570f9fbbe4101', {
    cluster: 'mt1',
    encrypted: true,
});

const echo = new Echo({
    broadcaster: 'pusher',
    key: '18721b6570f9fbbe4101',
    cluster: 'mt1',
    encrypted: true,
    wsHost: 'https://127.0.0.1',
    wsPort: 8000, // Use your Laravel app URL and the configured WebSocket port
    client: pusher,
});

export default echo;
