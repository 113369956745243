import { Outlet } from 'react-router-dom';

const RootTwo = () => {

	return(

		<>
			<Outlet />
		</>
	)

}

export default RootTwo;