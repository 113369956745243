import Table from './Main/Table';

function Deadlines() {

  //const [ modal, setModal ] = useState(true);

  return (

    <div>
        <h4 className="mt-5">Deadlines</h4>
        <Table />
    </div>
  );
}

export default Deadlines;
