import Table from './Main/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faFile, faUsers, faBell, faEnvelopeOpenText, faPhoneSquare } from '@fortawesome/free-solid-svg-icons';
import {useLoaderData, useNavigation} from 'react-router-dom';
import ProgressBar from "@ramonak/react-progress-bar";
import Context from './Context';
import {useContext, useState, useEffect} from 'react';

import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';

function Home() {

  //const [ modal, setModal ] = useState(true);
    const [ notify, setNotify ] = useState();

    const [ assessment, setAssessment ] = useState();

  const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

  const note = async() => {
     window.scrollTo(0, 0);
     const res = await fetch(`https://api.te-amo.co.za/public/api/notify/get?id=${main}`);
     const data = await res.json();

     const res2 = await fetch(`https://api.te-amo.co.za/public/api/tasks/assess/callout/count?id=${main}`);
     const data2 = await res2.text();

     console.log(data2);

     setNotify(data);
     setAssessment(data2);
  }

  useEffect(() => {
      note();
  }, []);

  let tasksAmount = useLoaderData();

  return (
    <>
    <div className="contentHeader shadow-sm sticky-top">
        <div className="row">
            <div className="col-md-10">
            <h4 className="TaskContentHeading">Dashboard</h4>
            </div>

            <div className="col-md-2">
                <div className="row">
                    <div className="col-md-2">
                        {notify>0?
                            <div className="containerBadge">
                                <NotificationBadge label='!' count={1} effect={Effect.SCALE} />
                            </div>
                          :
                          ''
                        }
                    </div>
                    <div className="col-md-10">
                        <FontAwesomeIcon icon={faBell} className="icon-top" />
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div className="dashboardArea">
        <p className="mt-5 welcome text-dark">Welcome
        <h4>{name}</h4>
        </p>


        
        <div className="row">
            <div className="col-md-6">
                <small className="j-tracker">Job Tracker</small>
                <div className="shadow tableCard">
                	{/* <FontAwesomeIcon icon={faTasks} className="icon" /> */}
                	

                    <div className="row">

                        <div className="col-md-3">
                            <b className="text-job">Total Jobs:</b>
                        </div>

                        <div className="col-md-2">
                        <h3>{useNavigation.state=="loading"?'Loading...':tasksAmount?.total}</h3>
                        </div>
                    </div>


                <div className="row">


                    <div className="col-4">
                        <div className="row">

                            <div className="col-lg-4">
                                <p>Open:</p>
                            </div>

                            <div className="col-lg-2">
                                <p>{useNavigation.state=="loading"?'Loading...':tasksAmount?.active}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="row">

                            <div className="col-lg-4">
                                <p>Closed:</p>
                            </div>

                            <div className="col-lg-2">
                                <p>{useNavigation.state=="loading"?'Loading...':tasksAmount?.completed}</p>
                            </div>
                        </div>
                    </div>

                    <div className="col-4">
                        <div className="row">

                            <div className="col-lg-5">
                                <p>Pending:</p>
                            </div>

                            <div className="col-lg-2">
                                <p>{useNavigation.state=="loading"?'Loading...':tasksAmount?.pending}</p>
                            </div>
                        </div>
                    </div>

                </div>

                    <ProgressBar className="progressBar" completed={Math.round((tasksAmount?.completed/tasksAmount?.total)*100)} maxCompleted={100} bgColor="#000"/>
                	
                	<FontAwesomeIcon icon="fa-solid fa-bars-progress" />
                </div>
            </div>

            <div className="col-md-6">
                <small className="j-tracker">Customers</small>
                <div className="shadow tableCard">
                	<FontAwesomeIcon icon={faUsers} className="icon" />
                	<p>Total customers: <h3>{tasksAmount?.customers}</h3></p>
                </div>
            </div>
        </div>


        <div className="row">
            {/*<small className="j-tracker">Call out / Assessment job</small>*/}
            <div className="col-md-4">
                <small className="j-tracker">Call out / Assessment job</small>
                <div className="shadow-lg tableCard">
                	<FontAwesomeIcon icon={faPhoneSquare} className="icon" />
                	<p>Call out / Assessment job: <h3>{assessment}</h3></p>
                </div>
            </div>

            {/*<small className="j-tracker">Call out / Assessment job</small>*/}
            <div className="col-md-8">
                <small className="j-tracker">Enquiries</small>
                <div className="shadow-lg tableCard">
                    <FontAwesomeIcon icon={faEnvelopeOpenText} className="icon" />
                    <p>New enquiries: <h3>{notify}</h3></p>
                </div>
            </div>
        </div>
        
    </div>
    </>
  );
}

export default Home;
