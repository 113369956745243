import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faHome, faBriefcase, faUsers} from '@fortawesome/free-solid-svg-icons';
import {Link, NavLink} from 'react-router-dom';

const MobileBottomBar = () =>
{
	return(
		<nav className="navbar fixed-bottom navbar-dark bg-dark btmNav">
		  <div className="container">
		    <div className="row myMobileNav">
		    	<div className="col-4 text-light text-center">
		    		<NavLink to='/' className="myMobileNavLink">
			    		<FontAwesomeIcon icon={faHome} className="icon" /><br />
			    		<small>Home</small>
		    		</NavLink>
		    	</div>

		    	<div className="col-4 text-light text-center">
		    		<NavLink to='/tasks' className="myMobileNavLink">
			    		<FontAwesomeIcon icon={faBriefcase} className="icon" /><br />
			    		<small>Job</small>
		    		</NavLink>
		    	</div>

		    	<div className="col-4 text-light text-center">
		    		<NavLink to='/customers' className="myMobileNavLink">
			    		<FontAwesomeIcon icon={faUsers} className="icon" /><br />
			    		<small>Customers</small>
		    		</NavLink>
		    	</div>
		    </div>
		  </div>
		</nav>
	);
}

export default MobileBottomBar