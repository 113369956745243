import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useState, useContext, useEffect, useRef} from 'react';
import Context from './Context';
import Select from 'react-select';

const Assign = ({open, close, id, setSpinner, setData}) =>
{	
	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);
	const [stuff, setStuff] = useState();
	const [chosen, setChosen] = useState();

	const selectRef = useRef();

	const handleSub = async (e) => {
		e.preventDefault();
		close();
		setSpinner(true);
		const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/${id}/action`, {
        method: 'PUT',
        headers: {
                'Content-Type': 'application/json',
        },
        body: JSON.stringify({'tech': chosen}),
      })

      if (res.ok) {
        //throw new Error('Network response was not ok');
      	setSpinner(false);
        toast.success('Job was Assigned to tech', {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        
        const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/paginateWeb?main=${main}`);
        const data = await res.json();
        setData(data.data);
      }
      else
      {
      	setSpinner(false);
        toast.error('Job failed to assign... please check network connection!!!', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
        });
      }
	}

	const getStuff = async() => 
	{
		const opt = [];
		const res = await fetch(`https://api.te-amo.co.za/public/api/stuff?main=${main}`);
		const data = await res.json();

		data.map((tech) => (
				opt.push({ value: tech.Email, label: tech.Name })
		));
		console.log(opt);
		setStuff(opt);
	}

	const options = [
	  { value: 'chocolate', label: 'Chocolate' },
	  { value: 'strawberry', label: 'Strawberry' },
	  { value: 'vanilla', label: 'Vanilla' }
	];

	useEffect(() => {
	  	
			getStuff();

	}, []);

	const multySelect = (option) => {
				setChosen(option);
	}

	
	if(!open)
	{
		return(

			<div className="myOverlay">
				<div className="myModal">
					<strong onClick={close}>X</strong><br /><br />
					<form method="post" onSubmit={handleSub} >
					 {/* <select class="form-select" aria-label="Default select example" onFocus={getStuff} onChange={(e)=>setChosen(e.target.value)}>
						  <option selected hidden>Assign employee</option>
						  {
									stuff?.map((member) => <option value={member.Email}>{member.Name}</option>)
						  }
					  </select>
					  
					  <br />*/}

						<Select options={

								stuff

						} isMulti ref={selectRef} onFocus={getStuff} onChange={multySelect} />

						<br />
					  
					  
					  <input type="submit" className="btn btn-dark" value="Submit"/>
					</form>
				</div>
			</div>
		)
	}
}

export default Assign