import {useState, useContext} from 'react';
import {NavLink} from 'react-router-dom';
import Context from './Context';

const Tabs = ({setTaskData, soloJobDetails, setSoloJobDetails, customerId }) => {

	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

	
	const [changePage, setChangePage] = useState(false);

	const getTask = async() => {
	  console.log(soloJobDetails);
      /*const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/${soloJobDetails.id}`);
      const data = await res.json();

      setSoloJobDetails(data);
      return data;*/
  }

  const getCustomer = async(id) => {

      const res = await fetch(`https://api.te-amo.co.za/public/api/customers/${id}`);
      const data = await res.json();

      //const res2 = await fetch(`https://api.te-amo.co.za/public/api/tasks/${id2}`);
      //const data2 = await res2.json();

      //setSoloCustomer(data);
      //setCusId(data[0].id)
      //console.log(data[0].id);
      setChangePage(true);

      //setSoloJobDetails(data2);
      //return data;
  }

	return(
		<ul className="nav nav-tabs">
		  <li className="nav-item">
		    <NavLink className="nav-link" aria-current="page" to={`/tasks/${cusId}/customer_details`}>Customer Details</NavLink>
		  </li>
		  <li className="nav-item">
		    <NavLink className="nav-link" aria-current="page" to={`/tasks/${jobId}/job_details`} onClick={()=>getCustomer(customerId)}>Job Details</NavLink>
		  </li>
		  <li className="nav-item">
		    <NavLink className="nav-link" to={`/tasks/${jobId}/attachments`}>Attachments</NavLink>
		  </li>
		  {/*<li className="nav-item">
		    <NavLink className="nav-link" to="/tasks/id/appointments">Appointments</NavLink>
		  </li>
		  <li className="nav-item">
		    <NavLink className="nav-link" to="/tasks/id/summary">Summary</NavLink>
		  </li>*/}
		</ul>
	)
}

export default Tabs