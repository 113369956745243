import Context from './Context';
import {useContext, useState} from 'react';


const AddStuff = ({open, close, submitForm, setData, setDataBackup}) => {

	const [thisName, setThisName] = useState('');
	const [last, setLast] = useState('');
	const [phone, setPhone] = useState('');
	const [thisEmail, setThisEmail] = useState('');
	const [location, setLocation] = useState('');
	const [occupation, setOccupation] = useState('');
	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

	const handleSub = async(e) => {
		e.preventDefault();
		
		await submitForm({'name': thisName, 'last': last, 'phone': phone, 'email': thisEmail, 'main': email, 'occupation': occupation});

		const res = await fetch(`https://api.te-amo.co.za/public/api/stuff?main=${main}`);
		const data = await res.json();

		console.log(data);

		setData(data);
		setDataBackup(data);

		setThisName('');
		setLast('');
		setPhone('');
		setThisEmail('');
		setLocation('');
	}
	if(!open){
		return (

			<div className="myOverlay">
				<div className="myModal">
					<strong onClick={close}>X</strong><br /><br />
					<form method="post" onSubmit={handleSub} >

					  <div className="form-group">
						  <select className="form-control" id="inlineFormCustomSelectPref" name="cat" value={occupation}  onChange={(e) => setOccupation(e.target.value)}>
						    <option hidden>Office or field user?</option>
						    <option value='office'>Office</option>
						    <option value='field'>Field</option>
						  </select>
					  </div><br />

					  <div className="form-group">
					  	<label for="exampleFormControlTextarea1">First name</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Name" name="task" value={thisName} onChange={(e) =>setThisName(e.target.value)}/>
					    
					  </div><br />
	
				  
					  <div className="form-group">
					  	<label for="exampleFormControlTextarea1">Last name</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Lastname" name="task" value={last} onChange={(e) =>setLast(e.target.value)}/>
					    
					  </div>
						
					  <br />

					  
					  <div className="form-group">
					  	<label for="exampleFormControlTextarea1">Phone</label>
					    <input type="number" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Phone No." name="task" value={phone} onChange={(e) =>setPhone(e.target.value)}/>
					  </div><br />
	
					  <div className="form-group">
					  	<label for="exampleFormControlTextarea1">Email</label>
					    <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Email" name="task" value={thisEmail} onChange={(e) =>setThisEmail(e.target.value)}/>
					  </div><br />
			
					  

					  <input type="submit" className="btn btn-dark" value="Submit"/>
					</form>
				</div>
			</div>

		);
	}
}

export default AddStuff