import {Link} from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';
import Tabs from './Tabs';
import Context from './Context';

import {PhotoProvider, PhotoView} from 'react-photo-view';

import 'react-photo-view/dist/react-photo-view.css';

const Attachments = ({soloJobDetails}) => {
const [dataUrl, setDataUrl] = useState(null);
const [noFile, setNoFile] = useState(false);
const [imgLoaded, setImgLoaded] = useState(false);
const [noImg, setNoImg] = useState(false);

const [imgs, setImgs] = useState(false);

const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

useEffect(() => {
      window.scrollTo(0, 0);
    }, []);

useEffect(() => {
const fetchDataUrl = async () => {
  try {
    const response = await fetch(`https://api.te-amo.co.za/public/api/tasks/view/work/${jobId}`);
    if (!response.ok) {
      throw new Error('Network response was not ok');

      console.log('Good');
    }
    
    const contentType = response.headers.get("content-type");

    if (contentType && contentType.includes('application/json')) {
    		//setNoImg(true);
      	const data = await response.json();

      	console.log(data);

		    if(data.error === "file not found")
		    {
		    	setNoImg(true);
		    }
		    else
		    {
		    	setImgs(data);
		    	setImgLoaded(true);
		    }
    }
    else{

	    const blob = await response.blob();
	    const reader = new FileReader();
	    reader.onloadend = () => {
	      setDataUrl(reader.result);
	      setImgLoaded(true);
	    };
	    reader.readAsDataURL(blob);
	  }

  } catch (error) {
  	setNoImg(true);
    console.error('Error fetching blob data:', error);
  }
};

fetchDataUrl();
}, []);


	return(
		<>
			<div className="contentHeader shadow-sm sticky-top"><h4 className="TaskContentHeading">Attachments </h4></div>

        	<div className="maiSecNave"><Link to="/">Dashboard</Link> > <Link to="/tasks">Jobs</Link> ><b>{`JB${jobId}`}</b></div>

        	<div className="TableArea">
				<Tabs />
				<div className="attachImg">
					<PhotoProvider>
					{imgLoaded?
						imgs?.map((img) => (
								<PhotoView src={`data:${img.mimeType};base64,${img.blob}`} >
									<img src={`data:${img.mimeType};base64,${img.blob}`} alt="Blob Image" height='300' style={{margin: 10}} />
								</PhotoView>
						))
						:(noImg?<div className="imgLoader">No files</div>:
						<div className="imgLoader">Loading...</div>)
					}
					</PhotoProvider>
				</div>
			</div>
		</>
	)

}

export default Attachments;