import {useState, useEffect} from 'react';
import OverlaySpinner from '../OverlaySpinner';
import { toast } from 'react-toastify';
import {Link, useParams} from 'react-router-dom';

const UpdatePass = () => {

	const [sub, setSub] = useState(false);
	const [loader, setLoader] = useState(false);
	const [thisEmail, setThisEmail] = useState();
	const [password, setPassword] = useState();
	const [passwordConfirm, setPasswordCornfirm] = useState();
	let { id } = useParams();

	useEffect(() => {
	  localStorage.setItem("logedIn2", JSON.stringify(false));
	}, []);

	const registerForm = async (e) => {
		e.preventDefault();

			if(password !== passwordConfirm)
			{
				const incorrect = document.getElementById('incorrect');
	      		incorrect.textContent = 'Password and confirm password must match';
				incorrect.style.color = 'red';
			}
			else{
				setLoader(true);
				const res = await fetch(`https://api.te-amo.co.za/public/api/stuff/${id}/password/update`, {

		      method: 'PUT',
		      headers: {
		              'Content-Type': 'application/json',
		            },
		            body: JSON.stringify({'email': thisEmail, 'newPassWord': password}),

		    });


		    if (!res.ok) {
		      throw new Error(`HTTP error! Status: ${res.status}`);
		    }
		    else
		    {
		    	setLoader(false);
		    	setThisEmail('');
		    	setPassword('');
		    	setPasswordCornfirm('');

		    	toast.success('Password was updated, you can sign in', {
		          position: "top-right",
		          autoClose: 5000,
		          hideProgressBar: false,
		          closeOnClick: true,
		          pauseOnHover: true,
		          draggable: true,
		          progress: undefined,
		          theme: "dark",
		        });
		    }
		  }
	}

	const varify = (e) => {

		if(e.target.value === '')
		{
			//console.log(e.target.value);
			let emailField = document.getElementById('emailRequired');
			let emailFieldR = document.getElementById('emailField');
			emailField.textContent = 'email is a required field';
			emailField.style.color = 'red';
			emailFieldR.style.border = '1px solid red';
		}
	}

	const varify2 = (e) => {

		if(e.target.value === '')
		{
			let passwordField = document.getElementById('passwordRequired');
			let passField = document.getElementById('passField');
			passwordField.textContent = 'password is a required field';
			passwordField.style.color = 'red';
			passField.style.border = '1px solid red';
		}
	}

	const varify5 = (e) => {

		if(e.target.value === '')
		{
			let passwordField = document.getElementById('passwordCornfRequired');
			let passField = document.getElementById('passCornfField');
			passwordField.textContent = 'password is a required field';
			passwordField.style.color = 'red';
			passField.style.border = '1px solid red';
		}
	}

	return(

		<div className="authForms">
			<small id="incorrect"></small>
			<form method="post" onSubmit={(e)=>registerForm(e)} >

			  <label htmlFor="exampleFormControlTextarea1">Email</label>
			  <div className="form-group">
			    <input type="email" className="form-control" id="emailField" aria-describedby="emailHelp" placeholder="Email" name="task" value={thisEmail} onChange={(e)=>setThisEmail(e.target.value)} onBlur={varify} />
			    <small id="emailRequired"></small>
			  </div><br />


			  <div className="form-group">
			    <label htmlFor="exampleFormControlTextarea1">New password</label>
			    <input type="password" className="form-control" id="passField" aria-describedby="emailHelp" placeholder="New password" name="task" value={password} onChange={(e)=>setPassword(e.target.value)} onBlur={varify2} />
			    <small id="passwordRequired"></small>
			  </div><br />

			  <div className="form-group">
			    <label htmlFor="exampleFormControlTextarea1">Confirm new password</label>
			    <input type="password" className="form-control" id="passCornfField" aria-describedby="emailHelp" placeholder="Confirm new password" name="task" value={passwordConfirm} onChange={(e)=>setPasswordCornfirm(e.target.value)} onBlur={varify5} />
			    <small id="passwordCornfRequired"></small>
			  </div><br />


			  <input type="submit" className="btn btn-dark" value="Update" disabled={sub}/>
			</form>
			<small>Already have an account? <b><Link className='authLink' to='/'>Sign in</Link></b></small>
		</div>

	);
}

export default UpdatePass;