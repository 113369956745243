import Table from './Main/Table';

function Inoives() {

  return (

    <div>
        <h4 className="mt-5">Unsent invoices</h4>
        <Table />
    </div>
  );
}

export default Inoives;
