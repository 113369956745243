import {useState, useContext} from 'react';
import Context from './Context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import LoadingOverlay from 'react-loading-overlay';
import { RouletteSpinner } from 'react-spinner-overlay';
import OverlaySpinner from '../OverlaySpinner';


const Modal = ({open, close, submitForm, setSpinner, setModal}) => {

	const [logedIn, setLogedInn, token, setToken, name, setName, email, setEmail, main, setMain] = useContext(Context);

	const [thisName, setThisName] = useState('');
	const [phone, setPhone] = useState('');
	const [thisEmail, setThisEmail] = useState('');
	const [location, setLocation] = useState('');
	const [job, setJob] = useState('');
	const [jobType, setJobType] = useState('start');
	const [customers, setCustomers] = useState();
	const [customerId, setCustomerId] = useState();
	const [modalPage, setModalPage] = useState(1);

	const [descrOfJob, setDescrOfJob] = useState();
	const [serialNo, setSerialNo] = useState();
	const [modelMake, setModelMake] = useState();
	const [accessories, setAccessories] = useState();
	const [conditioItem, setConditionItem] = useState();
	const [assementFee, setAssementFee] = useState();
	const [intallType, setIntallType] = useState();
	const [itemsNeeded, setItemsNeeded] = useState();
	const [additionalItems, setAdditionalItems] = useState();
	const [isActive, setIsActive] = useState(false);
	

	const handleSub = async(e) => {
		e.preventDefault();
		setModal(true);
		setSpinner(true);
		
		await submitForm({'name': thisName, 'job': job, 'admin': email, 'main': main, customer_id: customerId, 'type': jobType, 'dscr_job':descrOfJob, 'serial_no':serialNo, 'model_make':modelMake, 'accessories': accessories, 'condition': conditioItem, 'assessment_fee': assementFee, 'intall_type': intallType, 'needed_items': itemsNeeded, 'additional_items': additionalItems});

		setPhone('');
		setThisEmail('');
		setLocation('');
		setJob('');

		setDescrOfJob('');
		setSerialNo('');
		setModelMake('');
		setAccessories('');
		setConditionItem('');
		setAssementFee('');
		setIntallType('');
		setItemsNeeded('');
		setAdditionalItems('');
		setModalPage(1);

		setSpinner(false);
	}

	const getCustomers = async() => 
	{
		const res = await fetch(`https://api.te-amo.co.za/public/api/customers?main=${main}`);
		const data = await res.json();

		//console.log(data);
		setCustomers(data);
	}

	const autoFillForm = (e) =>
	{
		customers.forEach((customer) => {
			if(e === customer.Name)
			{
				setThisName(customer.Name);
				setPhone(customer.Phone_Number);
				setThisEmail(customer.Email);
				setLocation(customer.Address);
				setCustomerId(customer.id);
			}
		});
	}

	if(!open){
		return (
			<>
			<OverlaySpinner />
			<div className="myOverlay">
				<div className="myModal">
					

					{modalPage === 1?
					<>
					<strong onClick={close}>X</strong><br /><br />
					<form method="post" onSubmit={handleSub} >
					  <div className="form-group">
						  <select className="form-control" id="inlineFormCustomSelectPref" name="cat"  onFocus={getCustomers} onChange={(e) => autoFillForm(e.target.value)}>
						    <option hidden>Choose Customer</option>
						    {
						    	customers?.map((customer) => <option>{customer.Name}</option>)
						    }
						  </select>
					  </div><br />
					  

					  <label for="exampleFormControlTextarea1">Phone no.:</label>
					  <div className="form-group">
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Phone no." name="task" value={phone} />
					  </div><br />

					  <label for="exampleFormControlTextarea1">Email</label>
					  <div className="form-group">
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Email" name="task" value={thisEmail} />
					  </div><br />


					  <div class="form-group">
					    <label for="exampleFormControlTextarea1">Location</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Location" name="task" value={location} />
					  </div>
					  <br />


					  {/*<div class="form-group">
					    <label for="exampleFormControlTextarea1">Task</label>
					    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task" name="task" value={job} onChange={(e) => setJob(e.target.value)} />
					  </div>
					  <br />*/}

					  <div class="form-group">
					  	<select className="form-control" id="inlineFormCustomSelectPref" name="jobType" onChange={(e) => setJobType(e.target.value)}>
					  		<option hidden>Job Type</option>
					  		<option value="Call out">Call out</option>
					  		<option value="Assessment">Assessment</option>
					  		<option value="Installation">Installation</option>
					  	</select><br />
					  </div>

					  {jobType==="Call out"?
						  <>
						  <div class="form-group">
						    <label for="exampleFormControlTextarea1">Task</label>
						    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task" name="task" value={job} onChange={(e) => setJob(e.target.value)} />
						  </div>
						  <br />
						  </>
						  :''
					  }


					  
					  {jobType==="Call out" || jobType==='start'?
					  	<input type="submit" className="btn btn-dark" value="Submit"/>
					  	:
					  	<button className="btn btn-dark" onClick={()=>setModalPage(2)}>Next</button>
					  }
					</form>
					</>
					:

					modalPage===2 && jobType==="Assessment"?
					<>

					<div>
						<strong onClick={()=>setModalPage(1)} className="leftArrow"><FontAwesomeIcon icon={faArrowLeft} /></strong>
						<strong onClick={close}>X</strong>
					</div>

					<br /><br />
					<form method="post" onSubmit={handleSub} >
					  	  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Description of the job" name="descrOfJob" value={descrOfJob} onChange={(e) => setDescrOfJob(e.target.value)} />
						  </div><br />

						  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Serial number" name="serialNo" value={serialNo} onChange={(e) => setSerialNo(e.target.value)} />
						  </div><br />

						  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Model/make" name="modelMake" value={modelMake} onChange={(e) => setModelMake(e.target.value)} />
						  </div><br />

						  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Accessories" name="accessories" value={accessories} onChange={(e) => setAccessories(e.target.value)} />
						  </div><br />

						  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Condition of item" name="conditioItem" value={conditioItem} onChange={(e) => setConditionItem(e.target.value)} />
						  </div><br />

						  
						  <div className="form-group">
					    	<input type="text" className="form-control" placeholder="Assessment fee" name="assementFee" value={assementFee} onChange={(e) => setAssementFee(e.target.value)} />
					  	  </div><br />

						  <input type="submit" className="btn btn-dark" value="Submit"/>
					</form>
					</>
					:
					(modalPage===2 && jobType==="Installation"?
						<>

					<div>
						<strong onClick={()=>setModalPage(1)} className="leftArrow"><FontAwesomeIcon icon={faArrowLeft} /></strong>
						<strong onClick={close}>X</strong>
					</div>

					<br /><br />
					<form method="post" onSubmit={handleSub} >
					  	  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Installation type" name="descrOfJob" value={intallType} onChange={(e) => setIntallType(e.target.value)} />
						  </div><br />

						  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Items needed" name="itemsNeeded" value={itemsNeeded} onChange={(e) => setItemsNeeded(e.target.value)} />
						  </div><br />

						  
						  <div className="form-group">
						    <input type="text" className="form-control" placeholder="Additional items" name="additionalItems" value={additionalItems} onChange={(e) => setAdditionalItems(e.target.value)} />
						  </div><br />

						  <input type="submit" className="btn btn-dark" value="Submit"/>
					</form>
					</>
					:''
					)
					}
				</div>
			</div>
			</>

		);
	}
}

export default Modal