import Table from './Main/Table';
import AddStuff from './addStuff';
import {useState} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useLoaderData, useNavigation, useOutletContext, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import Scheduler from './Scheduler';

const Err = () =>
{
	const [ modal, setModal ] = useState(true);

  const customerNav = useNavigation();
  const customerOriginal = useLoaderData();

  const [ data, setData ] = useState(customerOriginal);
  const [ dataBackUp, setDataBackup ] = useState(customerOriginal);

  //console.log(customerOriginal);

  if(customerNav.state === 'loading')
  {
  	return(
  		<h3>loading...</h3>
  	)
  }

	return (

    <div>
    	<div className="contentHeader shadow-sm"><FontAwesomeIcon icon={faCog} /></div>

        <div className="maiSecNave"><Link to="/">Dashboard</Link> > <b>Error</b></div>

    	<div className="TableArea">
	        <h3>error 404</h3>
      </div>
    	{/*<Scheduler />*/}
    </div>
  );
}

export default Err