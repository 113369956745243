import {Link} from 'react-router-dom';
import {useState, useEffect, useContext} from 'react';
import { useParams } from 'react-router-dom';
import Table from './Main/Table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faBell } from '@fortawesome/free-solid-svg-icons';

import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import Context from './Context';

const Enquiry = () => {
	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);
	const { id } = useParams();
	const [returnData, setReturnData] = useState();

	const [ notify, setNotify ] = useState();

	const getInc = async() => {
		window.scrollTo(0, 0);
		const res = await fetch(`https://api.te-amo.co.za/public/api/enquiry/get?id=${main}`, {
		  method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'id': id}),
		})
		const data = await res.json();
		console.log(id);
		setReturnData(data);

		const res2 = await fetch('https://api.te-amo.co.za/public/api/enquiry/read', {
		  method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({'id': id}),
		})

		const res3 = await fetch(`https://api.te-amo.co.za/public/api/notify/get?id=${main}`);
     	const data3 = await res3.json();

     	setNotify(data3);


	}

	useEffect(() => {
	  getInc();
	}, []);

	return(
			<div>
				<div className="contentHeader shadow-sm sticky-top">
			        <div className="row">
			            <div className="col-md-10">
			            	<h4 className="TaskContentHeading">Enquiry</h4>
			            </div>

			            <div className="col-md-2">
			                <div className="row">
			                    <div className="col-md-2">
			                      {notify>0?
		                              <div className="containerBadge">
		                                  <NotificationBadge label='!' count={1} effect={Effect.SCALE} />
		                              </div>
		                            :
		                            ''
		                          }
			                    </div>
			                    <div className="col-md-10">
			                        <FontAwesomeIcon icon={faBell} className="icon-top" />
			                    </div>
			                </div>
			            </div>
			        </div>
			    </div>

				<div className="maiSecNave">

        			<div className="row">

        				<div className="col-md-9 mt-1">
	        				<Link to="/" className="maiSecNaveLink">Dashboard</Link> > Enquiry > <b>ENQ{id}</b>
	        			</div>

	        			<div className="col-md-3">
	        				<button className="btn btn-dark" >Make Job Card</button> <span className="btn btn-outline-dark">Delete</span>
	        			</div>

        			</div>
        		</div>

				<div className="textArea">
					<div className="container">
						<h5>Email: {returnData?.email}</h5>
						<h5>{returnData?.company?`Company: ${returnData?.company}`:`Name: ${returnData?.name}`}</h5><br />
			       		<p>{returnData?.message}</p>
			       	</div>
		      	</div>
			</div>
	);
}

export default Enquiry;