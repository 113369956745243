import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faTasks, faClock, faCog } from '@fortawesome/free-solid-svg-icons';
import {Link, useOutletContext, NavLink} from 'react-router-dom';
import {useState, useContext, useEffect, useRef} from 'react';
import Tabs from '../Tabs';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Context from '../Context';
//import DocComp from '../DocComp';
import ReactPDF from '@react-pdf/renderer';
import Select from 'react-select';

const JobDetails = ({soloJobDetails, setSoloJobDetails, spinner, setSpinner}) => {

	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);

	//const [tasks, setTasks, tasksBckUp, setTasksBckUp, assign, setAssign, id, setId, soloCustomer, setSoloCustomer] = useOutletContext();
//console.log(soloCustomer[0].Last_Name);

	const [thisName, setThisName] = useState(soloJobDetails.Task_Name);

	const [descrOfJob, setDescrOfJob] = useState(soloJobDetails.Description_of_job);
	const [serialNo, setSerialNo] = useState(soloJobDetails.Serial_number);
	const [modelMake, setModelMake] = useState(soloJobDetails.Model_make);
	const [accessories, setAccessories] = useState(soloJobDetails.Accessories);
	const [conditioItem, setConditionItem] = useState(soloJobDetails.Condition_item);
	const [assementFee, setAssementFee] = useState(soloJobDetails.Assement_fee);
	const [intallType, setIntallType] = useState(soloJobDetails.Intallation_type);
	const [itemsNeeded, setItemsNeeded] = useState(soloJobDetails.Items_neede);
	const [additionalItems, setAdditionalItems] = useState(soloJobDetails.Addition_Items);

	const [latName, setLatName] = useState(soloJobDetails.Assigned_to);
	const [taskData, setTaskData] = useState();
	const [save, setSave] = useState(true);
	const [stuff, setStuff] = useState();
	const [stuffAssind, setStuffAssind] = useState();

	//const [stuff, setStuff] = useState();
	const [chosen, setChosen] = useState();

	const selectRef = useRef();


	useEffect(() => {
  	window.scrollTo(0, 0);
  	const assineTechs = [];
  	//console.log(JSON.parse(soloJobDetails?.Assigned_to));
  	setTimeout(()=> {
  		/*if(soloJobDetails?.Assigned_to != 'none' || soloJobDetails?.Assigned_to != null || soloJobDetails?.Assigned_to != '')
  		{
  			JSON.parse(soloJobDetails?.Assigned_to)?.forEach((tech) => {
	  			assineTechs.push([tech.label]);
	  		});
  		}*/
  	}, 3000);

  	setStuffAssind(assineTechs);
  }, []);

	const submit = async (e) => {
		e.preventDefault();
		setSpinner(true);
		
		//console.log(thisName);

		const res = await fetch(`https://api.te-amo.co.za/public/api/tasks/${jobId}/update`, {

              method: 'PUT',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify({task: thisName, to: latName, dscr_job: descrOfJob, serial_no: serialNo, model_make: modelMake, accessories: accessories, condition: conditioItem, assessment_fee: assementFee, intall_type: intallType, needed_items: itemsNeeded, additional_items: additionalItems}),

          });

		  if (res.ok) {
            //throw new Error('Network response was not ok');
		  			setSpinner(false);
            toast.success('Job updated successfully', {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "dark",
            });
          }
          else
          {
          	setSpinner(false);
            toast.error('Job failed to updated please check network connection or contact your service provider!!!', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            });
          }
	}

	const thisSetName = (e) =>
	{
		setThisName(e);
		setSave(false);
	}

	const thisSetTo = (e) =>
	{
		setLatName(e);
		setSave(false);
	}

	const getStuff = async() => 
	{
		const res = await fetch(`https://api.te-amo.co.za/public/api/stuff?main=${main}`);
		const data = await res.json();

		//console.log(data);
		setStuff(data);
	}

	const getStuff2 = async() => 
	{
		const opt = [];
		const res = await fetch(`https://api.te-amo.co.za/public/api/stuff?main=${main}`);
		const data = await res.json();

		data.map((tech) => (
				opt.push({ value: tech.Email, label: tech.Name })
		));
		console.log(opt);
		setStuff(opt);
	}

	const multySelect = (option) => {
				setChosen(option);
	}

	return(
		<>
					<div className="contentHeader shadow-sm sticky-top"><h4 className="TaskContentHeading">Job details (edit)</h4></div>

        	<div className="maiSecNave"><Link to="/">Dashboard</Link> > <Link to="/tasks">Jobs</Link> ><b>{`JB${jobId}`}</b></div>



        	<div className="TableArea">
				<Tabs setTaskData={setTaskData} soloJobDetails={soloJobDetails} setSoloJobDetails={setSoloJobDetails} />
				<form className="editForm" onSubmit={submit}><br />

					{
						thisName?
						<>
						<div className="form-group">
						    <label htmlFor="exampleFormControlTextarea1">Task name</label>
						    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={thisName} onChange={(e) => thisSetName(e.target.value)} />
						</div><br />
						</>
						:
						(descrOfJob?
							<>
							<div className="form-group">
							    <label htmlFor="exampleFormControlTextarea1">Description of job</label>
							    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={descrOfJob} onChange={(e) => {setDescrOfJob(e.target.value); setSave(false);}} />
							</div><br />

							<div className="form-group">
							    <label htmlFor="exampleFormControlTextarea1">Serial Number</label>
							    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={serialNo} onChange={(e) => {setSerialNo(e.target.value); setSave(false);}} />
							</div><br />

							<div className="form-group">
							    <label htmlFor="exampleFormControlTextarea1">Model/make</label>
							    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={modelMake} onChange={(e) => {setModelMake(e.target.value); setSave(false);}} />
							</div><br />

							<div className="form-group">
							    <label htmlFor="exampleFormControlTextarea1">Accessories</label>
							    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={accessories} onChange={(e) => {setAccessories(e.target.value); setSave(false);}} />
							</div><br />

							<div className="form-group">
							    <label htmlFor="exampleFormControlTextarea1">Condition of the item</label>
							    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={conditioItem} onChange={(e) => {setConditionItem(e.target.value); setSave(false);}} />
							</div><br />

							<div className="form-group">
							    <label htmlFor="exampleFormControlTextarea1">Assement fee</label>
							    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={assementFee} onChange={(e) => {setAssementFee(e.target.value); setSave(false);}} />
							</div><br />
							</>

							:

							(intallType?
								<>
									<div className="form-group">
									    <label htmlFor="exampleFormControlTextarea1">Installation type</label>
									    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={intallType} onChange={(e) => {setIntallType(e.target.value); setSave(false);}} />
									</div><br />

									<div className="form-group">
									    <label htmlFor="exampleFormControlTextarea1">Items needed</label>
									    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={itemsNeeded} onChange={(e) => {setItemsNeeded(e.target.value); setSave(false);}} />
									</div><br />

									<div className="form-group">
									    <label htmlFor="exampleFormControlTextarea1">Additional items needed</label>
									    <input type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Task name" name="task" value={additionalItems} onChange={(e) => {setAdditionalItems(e.target.value); setSave(false);}} />
									</div><br />
								</>
								:
								''
							)
						)
					}

					<div className="form-group">
					    <label htmlFor="exampleFormControlTextarea1">Assigned to</label>
						    {/*<select class="form-select" aria-label="Default select example" onFocus={getStuff} onChange={(e)=>thisSetTo(e.target.value)}>
								  <option selected hidden>{latName}</option>
								  {
								    	stuff?.map((member) => <option value={member.Email}>{member.Name}</option>)
								  }
							  </select>*/}
						  <Select defaultValue={['colourOptions', 'colourOptions']} options={stuff} isMulti ref={selectRef} onFocus={getStuff2} onChange={() => setSave(false)} />

					</div><br />

					<input type="submit" value="Save" className="btn btn-dark" disabled={save} />
				</form>
			</div>
		</>
	)
}

export default JobDetails