import Scheduler from './Scheduler';
import {Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
const Appointments = () => {

	return(
		<>
			<div className="contentHeader shadow-sm"><FontAwesomeIcon icon={faCog} /></div>

	      	<div className="maiSecNave"><Link to="/">Dashboard</Link> > <b>Customers</b></div>
			<div className="TableArea">
				<Scheduler />
			</div>
		</>
	)

}

export default Appointments