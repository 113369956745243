import {NavLink, Link, redirect } from 'react-router-dom';
import Context from './Context';
import {useContext, useState} from 'react';
import OverlaySpinner from '../OverlaySpinner';
import { toast } from 'react-toastify';

const Register = () => {

	const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser] = useContext(Context);

	const [sub, setSub] = useState(false);
	const [loader, setLoader] = useState(false);

	const [userName, setUserName] = useState();
	const [thisEmail, setThisEmail] = useState();
	const [password, setPassword] = useState();
	const [passwordConfirm, setPasswordCornfirm] = useState();

	
	const registerForm = async (e) => {
		e.preventDefault();

			if(password !== passwordConfirm)
			{
				const incorrect = document.getElementById('incorrect');
	      incorrect.textContent = 'Password and confirm password must match';
				incorrect.style.color = 'red';
			}
			else{
			setLoader(true);
			const res = await fetch('https://api.te-amo.co.za/public/api/register', {

	      method: 'POST',
	      headers: {
	              'Content-Type': 'application/json',
	            },
	            body: JSON.stringify({'email': thisEmail, 'password': password, 'name': userName}),

	    });


	    if (!res.ok) {
	      throw new Error(`HTTP error! Status: ${res.status}`);
	    }
	    else
	    {
	    	const data = await res.json();

	    	if(data.Member_exists)
	    	{
	    		setLoader(false);
	    		toast.error('Email already exists', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
              });
	    	}
	    	else{
	    		setLoader(false);
		    	setUserName('');
		    	setThisEmail('');
		    	setPassword('');
		    	setPasswordCornfirm('');

		    	toast.success('account created, you can sign in', {
		          position: "top-right",
		          autoClose: 5000,
		          hideProgressBar: false,
		          closeOnClick: true,
		          pauseOnHover: true,
		          draggable: true,
		          progress: undefined,
		          theme: "dark",
		        });
	    	}
	    	
	    }
	  }
	}

	const varify = (e) => {

		if(e.target.value === '')
		{
			//console.log(e.target.value);
			let emailField = document.getElementById('emailRequired');
			let emailFieldR = document.getElementById('emailField');
			emailField.textContent = 'email is a required field';
			emailField.style.color = 'red';
			emailFieldR.style.border = '1px solid red';
		}
	}

	const varify2 = (e) => {

		if(e.target.value === '')
		{
			let passwordField = document.getElementById('passwordRequired');
			let passField = document.getElementById('passField');
			passwordField.textContent = 'password is a required field';
			passwordField.style.color = 'red';
			passField.style.border = '1px solid red';
		}
	}

	const varify5 = (e) => {

		if(e.target.value === '')
		{
			let passwordField = document.getElementById('passwordCornfRequired');
			let passField = document.getElementById('passCornfField');
			passwordField.textContent = 'password is a required field';
			passwordField.style.color = 'red';
			passField.style.border = '1px solid red';
		}
	}

	const varify3 = (e) => {

		if(e.target.value === 'none')
		{
			let occupationRequired = document.getElementById('occupationRequired');
			let occupationField = document.getElementById('occupationField');
			occupationRequired.textContent = 'Field is a required field';
			occupationRequired.style.color = 'red';
			occupationField.style.border = '1px solid red';
		}
	}

	const varify4 = (e) => {

		if(e.target.value === '')
		{
			let occupationRequired = document.getElementById('userNameRequired');
			let occupationField = document.getElementById('userNameField');
			occupationRequired.textContent = 'Field is a required field';
			occupationRequired.style.color = 'red';
			occupationField.style.border = '1px solid red';
		}
	}

	return(
		<>
			{loader?
              <OverlaySpinner />
              :
              ''
            }
			<div className="authForms2">
				<small id="incorrect"></small>
				<form method="post" onSubmit={(e)=>registerForm(e)} >

					<label htmlFor="exampleFormControlTextarea1">Username</label>
				  <div className="form-group">
				    <input type="text" className="form-control" id="userNameField" aria-describedby="emailHelp" placeholder="Username" name="task" value={userName} onChange={(e)=>setUserName(e.target.value)} onBlur={varify4} />
				    <small id="userNameRequired"></small>
				  </div><br />

				  <label htmlFor="exampleFormControlTextarea1">Email</label>
				  <div className="form-group">
				    <input type="email" className="form-control" id="emailField" aria-describedby="emailHelp" placeholder="Email" name="task" value={thisEmail} onChange={(e)=>setThisEmail(e.target.value)} onBlur={varify} />
				    <small id="emailRequired"></small>
				  </div><br />


				  <div className="form-group">
				    <label htmlFor="exampleFormControlTextarea1">Password</label>
				    <input type="password" className="form-control" id="passField" aria-describedby="emailHelp" placeholder="Password" name="task" value={password} onChange={(e)=>setPassword(e.target.value)} onBlur={varify2} />
				    <small id="passwordRequired"></small>
				  </div><br />

				  <div className="form-group">
				    <label htmlFor="exampleFormControlTextarea1">Confirm Password</label>
				    <input type="password" className="form-control" id="passCornfField" aria-describedby="emailHelp" placeholder="Confirm Password" name="task" value={passwordConfirm} onChange={(e)=>setPasswordCornfirm(e.target.value)} onBlur={varify5} />
				    <small id="passwordCornfRequired"></small>
				  </div><br />


				  <input type="submit" className="btn btn-dark" value="Create account" disabled={sub}/>
				</form>
				<small>Already have an account? <b><Link className='authLink' to='/'>Sign in</Link></b></small>
			</div>
		</>
	);
}

export default Register;