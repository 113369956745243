import {Link, redirect} from 'react-router-dom';
import Context from '../Context';
import {useContext, useState, useEffect} from 'react';
import OverlaySpinner from '../../OverlaySpinner';


const SideDropMenu = () => {
	const [logedIn, setLogedInn, token, setToken, name, setName, email, setEmail] = useContext(Context);
	const [spinner, setSpinner] = useState(false);
	

	useEffect(() => {
  		const hidden = document.getElementById('hidden');
		hidden.style.display = 'none';
	}, []);

	const showMenu = (byId) => {
		const hidden = document.getElementById('hidden');
		if(hidden.style.display == 'none')
			hidden.style.display = 'block';
		else
			hidden.style.display = 'none';
	}
	function logout()
	{
		setSpinner(true);
		redirect("/login");

		setTimeout(() => {
			localStorage.setItem("logedIn2", JSON.stringify(false));
			setLogedInn(false);
			setSpinner(false);
		}, 2500)
	}
	return(

		<div>
			{spinner?
              <OverlaySpinner />
              :
              ''
            }
			<div className="fixed-bottom mySideDropDown" >
	          <ul className="my-menu text-small shadow" id="hidden">
	            {/*<li><Link className="dropdown-item" to="#">Settings</Link></li>
	            <li><Link className="dropdown-item" to="#">Profile</Link></li>
	            <li><hr className="dropdown-divider" /></li>*/}
	            <Link className="dropdown-item" to="/" onClick={logout}><li>Sign out</li></Link>
	          </ul>

	          <strong className="dropdown-toggle" onClick={()=>showMenu()} style={{cursor: 'pointer'}}>{name}</strong>
	          

	        </div>
		</div>

	)

}

export default SideDropMenu;