function SideHeader ({TeAmoIcone}) {

	return(
		<div>
			<a href="/" className="d-flex align-items-center mb-0 mb-md-0 me-md-auto text-white text-decoration-none text-center">
	          <span className="fs-4 text-center ml-5"><img src={TeAmoIcone} className="icone" /></span>
	        </a>
	        <hr />
        </div>

	)
}

export default SideHeader