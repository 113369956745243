import Table from './Main/Table';
import AddStuff from './addStuff';
import {useState, useEffect, useContext} from 'react';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useLoaderData, useNavigation, useOutletContext, Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faBell } from '@fortawesome/free-solid-svg-icons';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

import NotificationBadge from 'react-notification-badge';
import {Effect} from 'react-notification-badge';
import Context from './Context';

const Customers = ({data, setData}) =>
{
  const [logedIn, setLogedIn, token, setToken, name, setName, email, setEmail, main, setMain, user, setUser, cusId, setCusId, jobId, setJobId] = useContext(Context);
  const customerNav = useNavigation();
  const customerOriginal = useLoaderData();

  //const [ data, setData ] = useState(customerOriginal);
  const [ dataBackUp, setDataBackup ] = useState(customerOriginal);

  const [ notify, setNotify ] = useState();

  console.log(customerOriginal);

  const note = async() => {
     window.scrollTo(0, 0);
     setData(customerOriginal);
     const res = await fetch(`https://api.te-amo.co.za/public/api/notify/get?id=${main}`);
     const data = await res.json();

     setNotify(data);
  }

  useEffect(() => {
    note();
  }, []);

  if(customerNav.state === 'loading')
  {
  	return(
  		<div className="TableArea">
        <SkeletonTheme baseColor="#dfdfdf" highlightColor="#afafaf" width="7%">
          <p>
            
            <Skeleton count={1} height="30px" width="10%"/><br /><br /><br />

            <div className="row">
              <div className="col-md-12" >
                <Skeleton count={1} height="450px" width="85%"/>
              </div>
            </div>
          </p>
        </SkeletonTheme>
      </div>
  	)
  }

	return (

    <div>
    	<div className="contentHeader shadow-sm sticky-top">
        <div className="row">
            <div className="col-md-10">
            <h4 className="TaskContentHeading">Customers</h4>
            </div>

            <div className="col-md-2">
                <div className="row">
                    <div className="col-md-2">
                        {
                          notify>0?
                            <div className="containerBadge">
                                <NotificationBadge label='!' count={1} effect={Effect.SCALE} />
                            </div>
                          :
                          ''
                        }
                    </div>
                    <div className="col-md-10">
                        <FontAwesomeIcon icon={faBell} className="icon-top" />
                    </div>
                </div>
            </div>
        </div>
    </div>

      <div className="maiSecNave"><Link to="/" className="maiSecNaveLink">Dashboard</Link> > <b>Customers</b></div>

    	<div className="TableArea">
	        <Table stuff={false} customers={true} data={data} setData={setData} dataBackUp={dataBackUp} />
      </div>
    	{/*<Scheduler />*/}
    </div>
  );
}

export default Customers